<template>
    <div class="container">
        <nav
            class="d-flex align-items-center justify-content-between navbar navbar-expand-xl navbar-light pb-0"
        >
            <a
                @click="$router.push('/')"
                class="mb-0 text-decoration-none text-body cursor"
                style="font-size : calc(100% + 1vw + 1vh);font-weight: bold"
                >Pyae Sone Shin</a
            >
            <button
                class="navbar-toggler bg-light"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
        </nav>
        <nav
            class="d-flex align-items-center justify-content-between navbar navbar-expand-xl navbar-light pt-0"
        >
            <div
                ref="navbarSupportedContent"
                class="collapse navbar-collapse"
                id="navbarSupportedContent"
            >
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item active">
                        <router-link
                            :to="{ name: 'Home' }"
                            class="nav-link mb-0 ps-0"
                            >Home</router-link
                        >
                    </li>
                    <li class="nav-item dropdown">
                        <a
                            class="nav-link mb-0 ps-0 dropdown-toggle"
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            All Jewellry
                        </a>
                        <div
                            class="dropdown-menu dropdown-bg-width"
                            aria-labelledby="navbarDropdown"
                        >
                            <!-- Categories -->
                            <li>
                                <p
                                    style="margin: 0 1rem;
                                        font-size: 1.2rem;
                                        font-weight: 700;"
                                >
                                    Categories
                                </p>
                            </li>
                            <li
                                v-for="category in categories"
                                :key="category.name"
                            >
                                <router-link
                                    style="color: black"
                                    class="dropdown-list fw-normal bg-transparent w-100"
                                    :to="{
                                        name: 'ProductList',
                                        query: {
                                            category_id: encryptAES(
                                                category.id,
                                                'pss',
                                            ),
                                            category_name: category.name,
                                        },
                                    }"
                                    >{{ category.name }}</router-link
                                >
                            </li>
                            <div class="dropdown-divider"></div>

                            <!-- Collections -->
                            <li>
                                <p
                                    style="margin: 0 1rem;
                                        font-size: 1.2rem;
                                        font-weight: 700;"
                                >
                                    Collections
                                </p>
                            </li>
                            <li
                                v-for="(collection, index) in collections"
                                :key="index"
                            >
                                <router-link
                                    style="color: black"
                                    class="dropdown-list fw-normal bg-transparent"
                                    :to="{
                                        name: 'ProductList',
                                        query: {
                                            collection_id: encryptAES(
                                                collection.id,
                                                'pss',
                                            ),
                                            collection_name: collection.name,
                                        },
                                    }"
                                    >{{ collection.name }}</router-link
                                >
                            </li>
                        </div>
                    </li>
                    <li class="nav-item">
                        <router-link
                            :to="{ name: 'Points' }"
                            class="nav-link mb-0 ps-0"
                            >Points Benefits</router-link
                        >
                    </li>
                    <li class="nav-item dropdown" v-if="activePromotion">
                        <a
                            class="nav-link mb-0 ps-0 dropdown-toggle"
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            Promotion
                        </a>
                        <div
                            class="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                        >
                            <!-- <a class="dropdown-list" href="#">{{
                                    promotion[0].title
                                }}</a> -->
                            <li>
                                <router-link
                                    style="color: black"
                                    class="dropdown-list fw-normal"
                                    :to="{
                                        name: 'PromotionPage',
                                    }"
                                >
                                    {{ activePromotion.title }}
                                </router-link>
                            </li>
                        </div>
                    </li>

                    <li class="nav-item">
                        <router-link
                            :to="{ name: 'Blog' }"
                            class="nav-link mb-0 ps-0"
                            >Knowledge blog</router-link
                        >
                    </li>
                    <li class="nav-item">
                        <!-- <a
                            class="nav-link mb-0 ps-0"
                            style="cursor: pointer;"
                            @click="pdfViewerModal = true"
                            >How to buy</a
                        > -->
                        <router-link
                            :to="{ name: 'HowToBuy' }"
                            class="nav-link mb-0 ps-0"
                            >How to buy</router-link
                        >
                    </li>
                </ul>
            </div>
            <div class="icon">
                <div
                    class="mobile-display d-flex flex-md-row align-items-center"
                >
                    <div class="d-flex align-items-center">
                        <div class="position-relative">
                            <heart-icon
                                class="me-3 cursor"
                                @click="toWishlist"
                                :class="{
                                    active:
                                        this.$route.path ===
                                        '/my-account/wishlist',
                                }"
                            />
                            <span
                                v-show="
                                    this.$route.path === '/my-account/wishlist'
                                "
                                class="cart-lists"
                                >{{ wishlistCount }}</span
                            >
                        </div>
                        <div class="position-relative">
                            <shopping-bag-icon
                                @click="toShoppingBag"
                                class="me-3 cursor"
                                :class="{
                                    active:
                                        this.$route.path ===
                                        '/my-account/shopping-bag',
                                }"
                            />
                            <span class="cart-lists">{{ carts.length }}</span>
                        </div>
                        <div class="nav-item dropdown">
                            <user-icon
                                class="dropdown-toggle me-3 cursor"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            />
                            <div class="dropdown-menu">
                                <li v-if="!isLoggedIn">
                                    <router-link
                                        class="dropdown-list"
                                        :to="{ name: 'Login' }"
                                        >Login</router-link
                                    >
                                </li>
                                <li v-if="!isLoggedIn">
                                    <router-link
                                        style="color: black"
                                        class="dropdown-list"
                                        :to="{ name: 'Register' }"
                                        >Register</router-link
                                    >
                                </li>
                                <li v-if="isLoggedIn">
                                    <a
                                        style="color: black"
                                        class="dropdown-list"
                                        @click="editProfile"
                                        href="javascript:void(0)"
                                        >Edit Profile</a
                                    >
                                </li>
                                <li v-if="isLoggedIn">
                                    <a
                                        style="color: black"
                                        class="dropdown-list"
                                        @click="orders"
                                        href="javascript:void(0)"
                                        >Orders</a
                                    >
                                </li>
                                <li v-if="isLoggedIn">
                                    <a
                                        style="color: black"
                                        class="dropdown-list"
                                        @click="pointHistory"
                                        href="javascript:void(0)"
                                        >Point history</a
                                    >
                                </li>
                                <li v-if="isLoggedIn">
                                    <a
                                        style="color: black"
                                        class="dropdown-list"
                                        @click="logout"
                                        href="javascript:void(0)"
                                        >Logout</a
                                    >
                                </li>
                            </div>
                        </div>
                        <li
                            class="d-none d-md-block nav-item dropdown"
                            style="list-style:none;"
                        >
                            <a
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <menu-icon class="me-3 cursor text-dark" />
                            </a>
                            <div
                                class="dropdown-menu"
                                aria-labelledby="navbarDropdown"
                            >
                                <!-- Categories -->
                                <li>
                                    <a
                                        style="color: black"
                                        class="dropdown-list fw-normal bg-transparent cursor"
                                        @click="toWishlist"
                                        >Wishlist</a
                                    >
                                </li>
                                <li>
                                    <router-link
                                        style="color: black"
                                        class="dropdown-list fw-normal bg-transparent cursor"
                                        :to="{ name: 'Blog' }"
                                        >Blog & Aritcle</router-link
                                    >
                                </li>
                                <li>
                                    <a
                                        style="color: black"
                                        class="dropdown-list fw-normal bg-transparent cursor"
                                        @click="pdfViewerModal = true"
                                        >Guide</a
                                    >
                                </li>
                                <div class="dropdown-divider"></div>

                                <!-- Collections -->
                                <li
                                    v-for="(collection,
                                    index) in menuIconSecond"
                                    :key="index"
                                >
                                    <router-link
                                        class="dropdown-list fw-normal bg-transparent"
                                        :to="{
                                            name: 'Home',
                                        }"
                                        >{{ collection }}</router-link
                                    >
                                </li>
                            </div>
                        </li>
                    </div>
                    <!-- {{ encryptAES('1', pss) }} -->

                    <p
                        v-if="
                            $route.name === 'Blog' ||
                                $route.name === 'BlogDetails'
                        "
                        class="d-flex justify-content-around blog-points"
                    >
                        <span
                            >View Video/Blog and
                            <span class="fw-bold"
                                >Get 50 Mining Points Daily</span
                            ></span
                        >
                        <span
                            >today {{ user ? user.todayBlogPoint : 0 }}/50</span
                        >
                    </p>
                    <button
                        v-else
                        class="py-2 px-3 border border-none outline-light wave"
                        style="color:white"
                        @click="toDownload"
                    >
                        <download-icon />
                        <span class="download" style="color: white;"
                            >Download Mobile App</span
                        >
                    </button>
                </div>
            </div>
        </nav>
        <!-- <div
            v-if="pdfViewerModal"
            class="modal pdfViewerModal"
            tabindex="-1"
            aria-labelledby="pdfViewerModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-fullscreen">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">How to buy?</h4>
                        <a
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            href="javascript:void(0)"
                            @click="pdfViewerModal = false"
                        ></a>
                    </div>
                    <div class="modal-body">
                        <embed
                            src="/documents/guide.pdf"
                            frameborder="0"
                            width="100%"
                            style="height: 100%;"
                        />
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import { onLogout } from '../../graphql/vue-apollo';
import { mapGetters } from 'vuex';
import encryptDecrypt from '@/mixins/PromotionEncryptDecrypt';

export default {
    mixins: [encryptDecrypt],
    data() {
        return {
            collectionDrop: false,
            categoryDrop: false,
            myAccountDrop: false,
            pdfViewerModal: false,
            menuIconFirst: ['Wishlist', 'Blog & Article', 'Guide'],
            menuIconSecond: [
                'Payment & Return Policy',
                'Delivering Policy',
                'Privacy Policy',
            ],
        };
    },

    computed: {
        ...mapGetters({
            // user: 'auth/getMyName',
            isLoggedIn: 'auth/isLoggedIn',
            collections: 'home/getCollections',
            categories: 'home/getCategories',
            activePromotion: 'promotion/getActivePromotion',
            wishlistCount: 'wishlist/getWishlistCount',
            carts: 'cart/getCarts',
            authUser: 'auth/getUser',
            user: 'auth/getUser',
        }),
    },

    methods: {
        logout() {
            onLogout(this.$apollo.provider.defaultClient);
            this.$store.commit('auth/REMOVE_AUTH');
            this.myAccountDrop = false;
            this.$store.commit('cart/RESET_CART');
            if (this.$route.name !== 'Home') {
                this.$router.push({ name: 'Home' });
            }
            this.$store.commit('home/ADD_SELECTED_REDEEM', null);
        },
        changeLanguage(lang) {
            this.$i18n.locale = lang;
        },
        toWishlist() {
            if (this.isLoggedIn) {
                this.$router.push({
                    name: 'WishList',
                });
            } else {
                this.$router.push({
                    name: 'Login',
                    query: {
                        slug: 'wishlist',
                    },
                });
            }
        },
        toShoppingBag() {
            this.$router.push({
                name: 'ShoppingBag',
            });
            // this.$router.replace({ path: '/my-account/shopping-bag' });
        },
        editProfile() {
            if (this.isLoggedIn) {
                this.$router.push({
                    name: 'ProfileEdit',
                });
            }
        },
        orders() {
            this.$router.push({
                name: 'MyOrder',
            });
        },
        pointHistory() {
            this.$router.push({
                name: 'PointHistory',
            });
        },
        toDownload() {
            this.$router.push({
                name: 'Download',
            });
        },
    },

    watch: {
        $route() {
            this.$refs.navbarSupportedContent.classList.remove('show');
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/components/navbar.scss';
.download {
    text-decoration: none;
    color: #fff !important;
}
.after::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border: 0;
}
.cart-lists {
    position: absolute;
    left: 1rem;
    bottom: 0.7rem;
    background: red;
    color: #fff;
    font-size: 0.7rem;
    width: 1rem;
    height: 1rem;
    text-align: center;
    border-radius: 50%;
    transition: linear 3s;
}
.dropdown-bg-width {
    width: 100%;
}
.mobile-display {
    margin-top: 1rem;
}
.blog-points {
    color: #fff;
    background: transparent
        linear-gradient(99deg, #cfff9a 0%, #57b20f 23%, #399410 100%) 0% 0%
        no-repeat padding-box;
    border: 1px solid #0000001a;
    border-radius: 23px;
    opacity: 1;
    padding: 0.3rem;
    width: 25rem;
    margin: 0;
    span {
        margin: 0;
        padding: 0;
        font-size: 0.8rem;
    }
}
@media (min-width: 576px) {
    .mobile-display {
        margin-top: 0rem;
    }
    .wave {
        width: 253px;
    }
}
@media (min-width: 992px) {
    .dropdown-bg-width {
        width: max-content;
    }
}
</style>

<template>
    <div>
        <layout />
        <transition name="fade">
            <router-view />
        </transition>
        <div
            v-if="notiModal"
            class="modal notiModal"
            tabindex="-1"
            aria-labelledby="notiModalLabel"
            aria-hidden="true"
        >
            <div id="confettis">
                <div class="confetti"></div>
                <div class="confetti"></div>
                <div class="confetti"></div>
                <div class="confetti"></div>
                <div class="confetti"></div>
                <div class="confetti"></div>
                <div class="confetti"></div>
                <div class="confetti"></div>
                <div class="confetti"></div>
            </div>
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header border-0">
                        <h4 class="mt-2">
                            <font-awesome-icon
                                :icon="['fas', 'exclamation-circle']"
                                class="logout-icon"
                            />
                            {{ noti.message }}
                        </h4>
                        <a
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            href="javascript:void(0)"
                            @click="notiModal = false"
                        ></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Layout from './layout/Layout';
import AddToWishList from '@/mixins/AddToWishlist';
import Items from '@/mixins/Items';
import { onLogout } from './graphql/vue-apollo';
// queries
import GetUser from '@/graphql/queries/auth/GetUser.gql';
import Categores from '@/graphql/queries/home/Categories.gql';
import SubscriptionType from '@/graphql/queries/footer/SubscriptionType.gql';
import ActivePromotion from '@/graphql/queries/home/ActivePromotion.gql';
import RewardHistory from '@/mixins/RedeemHistory';
import GoldTypes from '@/graphql/queries/home/GoldTypes.gql';

// mutations
import RefreshToken from './graphql/mutations/auth/refreshToken.gql';
import ManageDeviceId from './graphql/mutations/noti/manageDeviceId.gql';

import Pushy from 'pushy-sdk-web';

export default {
    mixins: [AddToWishList, Items, RewardHistory],

    components: {
        layout: Layout,
    },

    // created() {
    //     this.getMe();
    // },
    data() {
        return {
            noti: {
                message: '',
            },
            notiModal: false,
            current_page: 1,
        };
    },

    created() {
        this.getCollections();
        this.getCategories();
        this.getActivePromotion();
        this.getSubscriptionType();
        this.getGoldTypes();
        window.zESettings = {
            webWidget: {
                offset: {
                    horizontal: '50px',
                },
            },
        };
    },

    methods: {
        async getMe() {
            if (localStorage.getItem('token')) {
                try {
                    await this.$store.commit(
                        'auth/ADD_TOKEN',
                        localStorage.getItem('token'),
                    );
                    const response = await this.$apollo.query({
                        query: GetUser,
                        fetchPolicy: 'no-cache',
                    });
                    if (response) {
                        await this.$store.commit(
                            'auth/ADD_USER',
                            response.data,
                        );
                        this.initPushySdk();
                        this.getWishlists();
                        this.getRedeemHistory();
                    }
                } catch (errors) {
                    if (
                        errors &&
                        errors.graphQLErrors &&
                        errors.graphQLErrors[0].message ==
                            'Internal server error'
                    ) {
                        this.makeRefreshToken();
                    }
                }

                // this.$apollo
                //     .query({
                //         query: GetUser,
                //         fetchPolicy: 'no-cache',
                //     })
                //     .then(response => {
                //         this.$store.commit('auth/ADD_USER', response.data);
                //         this.getWishlists();
                //     })
                //     .catch(errors => {
                //         console.log(errors);
                //         if (
                //             errors &&
                //             errors.graphQLErrors &&
                //             errors.graphQLErrors[0].message ==
                //                 'Internal server error'
                //         ) {
                //             this.makeRefreshToken();
                //         }
                //     });
            }
        },

        getCategories() {
            this.$apollo
                .query({
                    query: Categores,
                })
                .then(response => {
                    this.$store.commit(
                        'home/ADD_CATEGORIES',
                        response.data.categories.data,
                    );
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getGoldTypes() {
            this.$apollo
                .query({
                    query: GoldTypes,
                })
                .then(response => {
                    this.$store.commit(
                        'home/ADD_GOLDTYPE',
                        response.data.goldtypes,
                    );
                })
                .catch(error => {
                    console.log(error);
                });
        },
        // getActivePromotion() {
        //     this.$apollo
        //         .query({
        //             query: ActivePromotion,
        //         })
        //         .then(response => {
        //             this.$store.commit(
        //                 'home/ADD_ACTIVE_PROMOTION',
        //                 response.data.activePromotion,
        //             );
        //         })
        //         .catch(error => {
        //             console.log(error);
        //         });
        // },
        getActivePromotion() {
            this.$apollo
                .query({
                    query: ActivePromotion,
                    fetchPolicy: 'no-cache',
                    variables: {
                        first: 10,
                        page: 1,
                    },
                })
                .then(response => {
                    this.$store.commit(
                        'promotion/ADD_ACTIVE_PROMOTION',
                        response.data.activePromotion,
                    );
                })
                .catch(error => {
                    console.log(error);
                });
        },
        async makeRefreshToken() {
            try {
                const data = await this.$apollo.mutate({
                    mutation: RefreshToken,
                    fetchPolicy: 'no-cache',
                    variables: {
                        refresh_token: localStorage.getItem('refresh_token'),
                    },
                });
                if (data) {
                    await this.$store.commit(
                        'auth/ADD_TOKEN',
                        data.data.refreshToken.access_token,
                    );
                    await this.$store.commit(
                        'auth/ADD_REFRESH_TOKEN',
                        data.data.refreshToken.refresh_token,
                    );
                    const me = await this.$apollo.query({
                        query: GetUser,
                        fetchPolicy: 'no-cache',
                    });
                    if (me) {
                        await this.$store.commit('auth/ADD_USER', me.data);
                        this.initPushySdk();
                        this.getWishlists();
                        this.getRedeemHistory();
                    }
                }
            } catch (error) {
                console.log(error);
                this.signOut();
            }
            // this.$apollo
            //     .mutate({
            //         mutation: RefreshToken,

            //         variables: {
            //             refresh_token: localStorage.getItem('refresh_token'),
            //         },
            //     })
            // .then(response => {
            //     console.log(response);
            //     this.$apollo
            //         .query({
            //             query: GetUser,
            //             fetchPolicy: 'no-cache',
            //         })
            //         .then(response => {
            //             this.$store.commit('auth/ADD_USER', response.data);
            //             this.getWishlists();
            //         })
            //         .catch(errors => {
            //             console.log(errors);
            //             this.signOut();
            //         });
            // })
            // .catch(errors => {
            //     console.log(errors);
            //     this.signOut();
            // });
        },

        signOut() {
            onLogout(this.$apollo.provider.defaultClient);
            this.$store.commit('auth/REMOVE_AUTH');
            this.$router.replace({ name: 'Home' });
        },

        getTokenForBaydin() {
            var formdata = new FormData();
            formdata.append('email', 'asatt@binarylab.io');
            formdata.append('password', '1qazxsw2!@#$');

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow',
            };

            fetch(
                'https://ibaydin.pyaesoneshin.com/ibaydin/public/api/login',
                requestOptions,
            )
                .then(response => response.json())
                .then(result => {
                    localStorage.setItem('baydin_token', result.data.jwt);
                })
                .catch(error => console.log('error', error));
        },

        initPushySdk() {
            // Register visitor's browser for push notifications
            let _this = this;

            Pushy.register({ appId: '624d554bca130a4f54f567df' })
                .then(function(deviceToken) {
                    // Print device token to console
                    // Send the token to your backend server via an HTTP GET request
                    //fetch('https://your.api.hostname/register/device?token=' + deviceToken);
                    _this.$apollo
                        .mutate({
                            mutation: ManageDeviceId,
                            fetchPolicy: 'no-cache',
                            variables: {
                                device_token: deviceToken,
                            },
                        })
                        .then(res => {
                            console.log(res);
                        })
                        .catch(err => {
                            console.log(err);
                        });
                    // Succeeded, optionally do something to alert the user
                })
                .catch(function(err) {
                    // Handle registration errors
                    console.error(err);
                });

            // Handle push notifications (only when web page is open)
            Pushy.setNotificationListener(function(data) {
                // Print notification payload data

                // Attempt to extract the "message" property from the payload: {"message":"Hello World!"}
                let message = data.message || 'Test notification';

                // Display an alert with message sent from server
                window.alert('Received notification: ' + message);
            });
        },
        async getSubscriptionType() {
            this.$apollo
                .query({
                    query: SubscriptionType,
                })
                .then(response => {
                    this.$store.commit(
                        'home/ADD_SUBSCRIPTIONTYPE',
                        response.data.allsubscriptiontypes,
                    );
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },

    watch: {
        $route() {
            setTimeout(() => {
                this.getMe();
            }, 100);
            // this.getMe();
            this.getCategories();
            this.initPushySdk();

            if (
                this.$route.name == 'BaydinList' ||
                this.$route.name == 'DemoniceyeAll' ||
                this.$route.name == 'DemoniceyeType' ||
                this.$route.name == 'DemoniceyeAnswer' ||
                this.$route.name == 'Tarot' ||
                this.$route.name == 'Lottery' ||
                this.$route.name == 'GreatBoard' ||
                this.$route.name == 'NumberFotune'
            ) {
                this.getTokenForBaydin();
            }
        },
    },
};
</script>

<style lang="scss">
.notiModal {
    background: #00000096;
    display: block !important;
}
.confetti {
    left: 50%;
    width: 16px;
    height: 16px;
    position: absolute;
    transform-origin: left top;
    animation: confetti 5s ease-in-out -2s infinite;
}
@keyframes confetti {
    0% {
        transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
    }
    25% {
        transform: rotateZ(5deg) rotateY(360deg) translate(-5vw, 20vh);
    }
    50% {
        transform: rotateZ(15deg) rotateY(720deg) translate(5vw, 60vh);
    }
    75% {
        transform: rotateZ(5deg) rotateY(1080deg) translate(-10vw, 80vh);
    }
    100% {
        transform: rotateZ(15deg) rotateY(1440deg) translate(10vw, 110vh);
    }
}
.confetti:nth-child(1) {
    left: 10%;
    animation-delay: 0;
    background-color: #fc0120;
}
.confetti:nth-child(2) {
    left: 20%;
    animation-delay: -5s;
    background-color: #8257e6;
}
.confetti:nth-child(3) {
    left: 30%;
    animation-delay: -3s;
    background-color: #ffbf4d;
}
.confetti:nth-child(4) {
    left: 40%;
    animation-delay: -2.5s;
    background-color: #fe5d7a;
}
.confetti:nth-child(5) {
    left: 50%;
    animation-delay: -4s;
    background-color: #45ec9c;
}
.confetti:nth-child(6) {
    left: 60%;
    animation-delay: -6s;
    background-color: #f6e327;
}
.confetti:nth-child(7) {
    left: 70%;
    animation-delay: -1.5s;
    background-color: #f769ce;
}
.confetti:nth-child(8) {
    left: 80%;
    animation-delay: -2s;
    background-color: #007de7;
}
.confetti:nth-child(9) {
    left: 90%;
    animation-delay: -3.5s;
    background-color: #63b4fc;
}
.confetti:nth-child(10) {
    left: 100%;
    animation-delay: -2.5s;
    background-color: #f9c4ea;
}
</style>

export const mmZLang = {
    navbar: {
        collections: 'မင်္ဂလာပါ zawgyi',
        categories: 'CATEGOမင်္ဂလာပါ zawgyiRIES',
        enquire: 'မင်္ဂလာပါ zawgyi',
        myAccount: {
            name: 'My Account',
            orders: 'Orders',
            cart: 'Cart',
            editProfile: 'Edit Profile',
            wishlist: 'Wishlist',
            changePassword: 'Change Password',
            logout: 'Logout',
        },
        search: {
            placeholder: 'Search',
        },
    },
    home: {
        carouselSlide: {
            button: 'DISCOVER COLLECTION',
            goldRateCircle: 'Gold Rate',
        },
        categoryArea: {
            header: 'Shop by Category',
        },
        newArrivalArea: {
            header: 'New arrivals',
            subHeader: 'Fresh and trending products for you',
        },
        genderArea: {
            header: 'Shop by Gender',
            weddingHeader: 'Couple Collections',
            weddingSubHeader: 'Find Out Our Exclusive Love Birds Collections',
        },
        topSellerArea: {
            header: 'Top Sellers',
            subHeader: 'Discover our best selling products',
            button: 'VIEW ALL',
        },
        shopByCollctionArea: {
            header: 'Shop by Collection',
        },
        ourStoreArea: {
            header: 'Our Stores',
            subHeader: 'Visit one of our exclusive stores or shop',
            shopAddress:
                'First floor, f-22, palladium, lower parel, mumbai, maharashtra -400013',
            detailLink: 'SHOP DETAILS',
        },
        contactUsArea: {
            header: 'Get The Latest From Us',
            subHeader:
                'Join our list to save 10% OFF your next purchase and get the latest on New Arrivals, Promotions, and Style Inspiration.',
            // 'JOIN OUR LIST TO SAVE 10% OFF YOUR NEXT PURCHASE AND GET THE LATEST ON NEW ARRIVALS, PROMOTIONS AND STYLE INSPIRATION.',
            input: {
                placeholder: 'Enter Your Email or Mobile Number',
            },
            button: 'SUBSCRIBE',
        },
    },
    profile: {
        myOrder: {
            header: 'My Orders',
            subHeader: 'Check your order history, details, and status.',
        },
        myCart: {
            header: 'My Carts',
            subHeader: 'Your cart listed items at one place',
            summaryHeader: 'Order Summary',
            summaryBox: {
                paymentType: 'Mode of Payment',
                subTotal: 'SubTotal',
                discount: 'Discount',
                tax: 'Tax',
                grandTotal: 'Grand Total',
                checkoutButton: 'PROCEED TO CHECKOUT',
                checkoutStatus:
                    '*Shipping charge may included depending upon your location. Please proceed further to know.',
            },
            detailHeader: 'Please fill your details',
            acceptTerms: 'I accept to use my information for sign up.',
            placeOrder: 'Place Order',
            paymentType: 'Mode of Payment: Cash on Delivery',
        },
        wishlist: {
            header: 'My Wishlist',
            subHeader: 'Your wish listed items at one place',
        },
        editProfile: {
            header: 'Edit Profile',
            subHeader: 'Update your profile info',
            updateButton: 'Update',
        },
        changePassword: {
            header: 'Change Password',
            subHeader: 'Change your password',
            button: 'CHANGE PASSWORD',
        },
        logout: {
            sureStatus: 'Are You Sure?',
        },
    },
    products: {
        header: 'All Products',
        sort: {
            header: 'Sort by',
            bestSeller: 'Best Seller',
            popularity: 'Popularity',
            discount: 'Discount',
            price: 'Price',
        },
        showmore: {
            button: 'SHOW MORE',
        },
        filters: {
            clear: 'Clear All Filter',
            diamond: 'Diamond',
            pricingGroup: 'Pricing Group',
            foundProducts: 'Found {n} Products',
        },
        pagiStatus: 'You are viewing {n} out of {c}.',
    },
    product: {
        detail: {
            weight: 'Gold Weight',
            quality: 'Gold Quality',
            color: 'Gold Color',
            colorStone: 'Color of stone',
            addToCartButton: 'Add To Cart',
            cod: 'Cash on Delivery',
            deliveryRange: 'Delivery in 8 - 10 days',
            instockStatus: 'In stock 23 Available',
            descriptiion: 'Product Description',
            youMayAlsoLike: {
                header: 'You may also like',
                button: 'VIEW MORE',
            },
        },
    },
};

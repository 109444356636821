// apollo query
import ShopByCollection from '@/graphql/queries/home/ShopByCollection.gql';
import Categores from '@/graphql/queries/home/Categories.gql';

const Items = {
    methods: {
        getCollections() {
            this.$apollo
                .query({
                    query: ShopByCollection,
                })
                .then(response => {
                    this.$store.commit(
                        'home/ADD_COLLECTIONS',
                        response.data.allCollections,
                    );
                })
                .catch(error => {
                    console.log(error);
                });
        },

        getCategories() {
            this.$apollo
                .query({
                    query: Categores,
                })
                .then(response => {
                    this.$store.commit(
                        'home/ADD_CATEGORIES',
                        response.data.categories.data,
                    );
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
};

export default Items;

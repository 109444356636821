const state = {
    taxes: null,
    orders: [],
    is_make_order: false,
    order: null,
};

const mutations = {
    ADD_TAXES: (state, data) => {
        state.taxes = data;
    },
    ADD_ORDERS: (state, data) => {
        state.orders = data;
    },
    APPEND_ORDERS: (state, data) => {
        state.orders.push(data);
    },
    MAKE_ORDER: (state, data) => {
        state.is_make_order = data;
    },
    ADD_ORDER: (state, data) => {
        state.order = data;
    },
};

const getters = {
    getTaxes: state => state.taxes,
    getOrders: state => state.orders,
    getIsMakeOrder: state => state.is_make_order,
    getOrder: state => state.order,
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
};

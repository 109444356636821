const state = {
    categories: null,
    products: null,
    product: null,
    promotion_variant_product: null,
    similar_products: null,
    subCategories: null,
};

const mutations = {
    ADD_CATEGORIES: (state, data) => (state.categories = data),
    ADD_SUB_CATEGORIES: (state, data) => (state.subCategories = data),
    ADD_PRODUCTS: (state, data) => (state.products = data),
    APPEND_PRODUCTS: (state, data) => state.products.data.push(data),
    ADD_PRODUCT: (state, data) => (state.product = data),
    ADD_PROMOTION_VARIANT_PRODUCT: (state, data) =>
        (state.promotion_variant_product = data),
    ADD_SIMILAR_PRODUCTS: (state, data) => (state.similar_products = data),
    APPEND_SIMILAR_PRODUCTS: (state, data) =>
        state.similar_products.data.push(data),
};

const getters = {
    getCategories: state => state.categories,
    getSubCategories: state => state.subCategories,
    getProducts: state => state.products,
    getProduct: state => state.product,
    getPromotionVariantProduct: state => state.promotion_variant_product,
    getSimilarProducts: state => state.similar_products,
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
};

<template>
    <div style="background: white;" class="sticky-top shadow">
        <nav-bar v-if="checkRoute" />
        <!-- <floating-ads></floating-ads> -->
    </div>
</template>

<script>
// import FloatingAds from '../components/layout/FloatingAds.vue';
import Navbar from '../components/layout/Navbar';
export default {
    components: {
        'nav-bar': Navbar,
        // 'floating-ads': FloatingAds,
    },

    computed: {
        checkRoute() {
            if (
                this.$route.name == 'Login' ||
                this.$route.name == 'Register' ||
                this.$route.name == 'forgetPassword' ||
                this.$route.name == 'updateUserPassword' ||
                // this.$route.name == 'Guide' ||
                this.$route.name == 'BaydinList' ||
                this.$route.name == 'DemoniceyeAll' ||
                this.$route.name == 'Calendar' ||
                this.$route.name == 'DemoniceyeType' ||
                this.$route.name == 'Tarot' ||
                this.$route.name == 'DemoniceyeAnswer' ||
                this.$route.name == 'Lottery' ||
                this.$route.name == 'GreatBoard' ||
                this.$route.name == 'NumberFotune'
            ) {
                return false;
            } else {
                return true;
            }
        },
    },
};
</script>

const state = {
    leaderboardAll: null,
    myPointPlace: null,
    shwePyaeRewardList: [],
    ngwePyaeRewardList: [],
    referralRewardList: [],
    redeemLoyalHistories: [],
    redeemReferralHistories: [],
    redeemActivityHistories: [],
    pointHistories: [],
};

const mutations = {
    ADD_POINT_HISTORY: (state, data) => {
        state.pointHistories = data;
    },

    ADD_MORE_POINT_HISTORY: (state, data) => {
        state.pointHistories.push(data);
    },

    ADD_LEADERBOARDS_ALL: (state, data) => {
        state.leaderboardAll = data;
    },
    ADD_MY_POINT_PLACE_INDEX: (state, data) => {
        state.myPointPlace = data;
    },
    ADD_SHWE_PYAE_REWARDS: (state, data) => {
        state.shwePyaeRewardList = data;
    },
    ADD_NGWE_PYAE_REWARDS: (state, data) => {
        state.ngwePyaeRewardList = data;
    },
    ADD_REFERRAL_REWARDS: (state, data) => {
        state.referralRewardList = data;
    },
    ADD_REDEEM_LOYAL_HISTORIES: (state, data) => {
        state.redeemLoyalHistories = data;
    },
    ADD_REDEEM_ACTIVITY_HISTORIES: (state, data) => {
        state.redeemActivityHistories = data;
    },
    ADD_REDEEM_REFERRAL_HISTORIES: (state, data) => {
        state.redeemReferralHistories = data;
    },
};

const getters = {
    getAllLeaderboards: state => state.leaderboardAll,
    getMyPointPlace: state => state.myPointPlace,
    getShwePyaeRewardList: state => state.shwePyaeRewardList,
    getNgwePyaeRewardList: state => state.ngwePyaeRewardList,
    getReferralRewardList: state => state.referralRewardList,
    getRedeemLoyalHistories: state => state.redeemLoyalHistories,
    getRedeemActivityHistories: state => state.redeemActivityHistories,
    getRedeemReferralHistories: state => state.redeemReferralHistories,
    getPointHistories: state => state.pointHistories,
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
};

const state = {
    promotionProducts: [],
    activePromotion: null,
};

const mutations = {
    ADD_PROMOTION_PRODUCTS: (state, data) => {
        state.promotionProducts = data;
    },
    APPEND_PROMOTION_PRODUCTS: (state, data) => {
        state.promotionProducts.push(data);
    },
    ADD_ACTIVE_PROMOTION: (state, data) => (state.activePromotion = data),
};

const getters = {
    getPromotionProducts: state => state.promotionProducts,
    getActivePromotion: state => state.activePromotion,
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
};

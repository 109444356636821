import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import VueMeta from 'vue-meta';

Vue.use(VueRouter);
Vue.use(VueMeta);
const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/auth/Login.vue'),
        beforeEnter: (to, from, next) => {
            if (localStorage.getItem('token')) {
                next({
                    path: '/',
                });
            } else {
                next();
            }
        },
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/auth/Register.vue'),
        beforeEnter: (to, from, next) => {
            if (localStorage.getItem('token')) {
                next({
                    path: '/',
                });
            } else {
                next();
            }
        },
    },
    {
        path: '/forget-password',
        name: 'forgetPassword',
        component: () => import('../views/auth/ForgotPassword.vue'),
        beforeEnter: (to, from, next) => {
            if (localStorage.getItem('token')) {
                next({
                    path: '/',
                });
            } else {
                next();
            }
        },
    },
    {
        path: '/update-user-password',
        name: 'updateUserPassword',
        component: () => import('../views/auth/UpdatePasswordForm.vue'),
        beforeEnter: (to, from, next) => {
            if (localStorage.getItem('token')) {
                next({
                    path: '/',
                });
            } else {
                next();
            }
        },
    },
    {
        path: '/sub-categories/:id',
        name: 'subCategoryList',
        component: () => import('../views/products/subCategoryList.vue'),
    },
    {
        path: '/my-account/wishlist',
        name: 'WishList',
        component: () => import('../views/profile/WishList.vue'),
        meta: { requireAuth: false },
    },
    {
        path: '/my-account/cart-list',
        name: 'CartList',
        component: () => import('../views/profile/CartList.vue'),
    },
    {
        path: '/my-account/shopping-bag',
        name: 'ShoppingBag',
        component: () => import('../views/profile/ShoppingBag.vue'),
    },
    {
        path: '/my-account/orders',
        name: 'MyOrder',
        component: () => import('../views/profile/Orders.vue'),
        meta: { requireAuth: true },
    },
    // {
    //     path: '/my-account/my-orders-detail',
    //     name: 'MyOrderDetail',
    //     component: () => import('../views/profile/MyOrderDetail.vue'),
    //     meta: { requireAuth: true },
    // },
    {
        path: '/my-account/order-detail',
        name: 'OrderDetail',
        component: () => import('../views/profile/OrderDetail.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/my-account/point-histories',
        name: 'PointHistory',
        component: () => import('../views/profile/PointHistory.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/my-account/edit',
        name: 'ProfileEdit',
        component: () => import('../views/profile/Edit.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/my-account/password/edit',
        name: 'PasswordEdit',
        component: () => import('../views/profile/ChangePassword.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/reward/shwe-pyae',
        name: 'ShwePyae',
        component: () => import('../views/reward/ShwePyae.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/reward/ngwe-pyae',
        name: 'NgwePyae',
        component: () => import('../views/reward/NgwePyae.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/reward/referral',
        name: 'ReferralPage',
        component: () => import('../views/reward/ReferralPage.vue'),
        meta: { requireAuth: true },
    },
    {
        path: '/baydin/list',
        name: 'BaydinList',
        component: () => import('../views/baydin/List.vue'),
    },
    {
        path: '/calendar',
        name: 'Calendar',
        component: () => import('../views/calendar/Calendar.vue'),
    },
    // {
    //     path: '/wishlist',
    //     name: 'WishList',
    //     component: () => import('../views/WishList.vue'),
    // },
    {
        path: '/terms-and-conditions',
        name: 'Terms',
        component: () => import('../views/Terms.vue'),
    },
    {
        path: '/points',
        name: 'Points',
        component: () => import('../views/Points.vue'),
    },
    {
        path: '/how-to-buy',
        name: 'HowToBuy',
        component: () => import('../views/HowToBuy.vue'),
    },
    {
        path: '/promotion',
        name: 'PromotionPage',
        component: () => import('../views/PromotionPage.vue'),
        props: true,
    },
    // {
    //     path: '/order-detail',
    //     name: 'OrderDetail',
    //     component: () => import('../views/OrderDetail.vue'),
    // },
    // {
    //     path: '/order-history',
    //     name: 'OrderHistory',
    //     component: () => import('../views/OrderHistory.vue'),
    // },
    // {
    //     path: '/shopping-bag',
    //     name: 'ShoppingBag',
    //     component: () => import('../views/ShoppingBag.vue'),
    // },
    {
        path: '/baydin/demonic-eye/all',
        name: 'DemoniceyeAll',
        component: () => import('../views/baydin/demoniceye/All.vue'),
    },
    {
        path: '/baydin/demonic-eye/type',
        name: 'DemoniceyeType',
        component: () => import('../views/baydin/demoniceye/Type.vue'),
    },
    {
        path: '/baydin/demonic-eye/answer/:id',
        name: 'DemoniceyeAnswer',
        component: () => import('../views/baydin/demoniceye/Answer.vue'),
    },
    {
        path: '/baydin/tarot',
        name: 'Tarot',
        component: () => import('../views/baydin/tarot/Tarot.vue'),
    },
    {
        path: '/baydin/lottery',
        name: 'Lottery',
        component: () => import('../views/baydin/lottery/Lottery.vue'),
    },
    {
        path: '/baydin/greatboard',
        name: 'GreatBoard',
        component: () => import('../views/baydin/greatboard/GreatBoard.vue'),
    },
    {
        path: '/baydin/numberfotune',
        name: 'NumberFotune',
        component: () =>
            import('../views/baydin/numberfotune/NumberFotune.vue'),
    },
    {
        path: '/downloads/android',
        name: 'android',
        component: () => import('../views/downloads/android.vue'),
    },
    {
        path: '/downloads/ios',
        name: 'ios',
        component: () => import('../views/downloads/ios.vue'),
    },
    {
        path: '/downloads/adaptive',
        name: 'adaptive',
        component: () => import('../views/downloads/dynamic.vue'),
    },
    {
        path: '/products',
        name: 'ProductList',
        component: () => import('../views/products/List.vue'),
    },
    {
        path: '/order-details',
        name: 'OrderDetail',
        component: () => import('../views/profile/OrderDetail.vue'),
    },
    {
        path: '/products/show/:slug',
        name: 'ProductDetail',
        component: () => import('../views/products/Detail.vue'),
    },
    {
        path: '/productvariants/show/:slug',
        name: 'ProductVariantDetail',
        component: () => import('../views/products/VariantDetail.vue'),
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/About.vue'),
    },
    {
        path: '/blogs',
        name: 'Blog',
        component: () =>
            import(/* webpackChunkName: "blog" */ '../views/blog/List.vue'),
    },
    {
        path: '/download',
        name: 'Download',
        component: () =>
            import(/* webpackChunkName: "blog" */ '../views/Download.vue'),
    },
    {
        path: '/blogs/:slug',
        name: 'BlogDetails',
        component: () =>
            import(/* webpackChunkName: "blog" */ '../views/blog/Details.vue'),
        props: true,
    },
    // {
    //     path: '/guide',
    //     name: 'Guide',
    //     component: () =>
    //         import(/* webpackChunkName: "blog" */ '../views/Guide.vue'),
    // },
];

const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requireAuth)) {
        if (!localStorage.getItem('token') && !store.getters['auth/getUser']) {
            next({
                name: 'Login',
                query: { redirect: to.fullPath },
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;

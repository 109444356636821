import Vue from 'vue';
import Vuex from 'vuex';

// modules
import auth from './auth/auth';
import home from './home/home';
import product from './product/product';
import wishlist from './wishlist/wishlist';
import cart from './cart/cart';
import order from './order/order';
import point from './point/point';
import blog from './blog/blog';
import comment from './comment/comment';
import promotion from './promotion/promotion';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        home,
        product,
        wishlist,
        cart,
        order,
        point,
        blog,
        comment,
        promotion,
    },
});

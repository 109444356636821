const state = {
    token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
    refreshToken: localStorage.getItem('refresh_token')
        ? localStorage.getItem('refresh_token')
        : null,
    user: null,
    userName: '',
    socialLoginUser: null,
};

const mutations = {
    ADD_TOKEN: (state, token) => {
        state.token = token;
        localStorage.setItem('token', state.token);
    },

    ADD_REFRESH_TOKEN: (state, token) => {
        state.refreshToken = token;
        localStorage.setItem('refresh_token', state.refreshToken);
    },

    ADD_USER: (state, user) => {
        state.user = user.me;
        state.userName = user.me.name;
    },

    ADD_SOCIAL_USER: (state, user) => {
        state.socialLoginUser = user;
    },

    UPDATE_USER: (state, user) => {
        state.user = user;
        state.userName = state.user.name;
    },

    REMOVE_AUTH: state => {
        state.token = null;
        state.user = null;
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
    },
};

const getters = {
    getToken: state => state.token,
    getRefreshToken: state => state.refreshToken,
    getUser: state => state.user,
    isLoggedIn: state => !!state.token,
    getMyName: state => state.userName,
    getSocialUser: state => state.socialLoginUser,
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
};

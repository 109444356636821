const state = {
    wishlists: null,
    wishlists_count: 0,
};

const mutations = {
    ADD_WISHLIST: (state, data) => {
        state.wishlists = data.wishlists;
        state.wishlists_count = data.count;
    },
    APPEND_WISHLISTS: (state, data) => {
        state.wishlists.data.push(data);
    },
};

const getters = {
    getWishlists: state => state.wishlists,
    getWishlistCount: state => state.wishlists_count,
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
};

const state = {
    categories: null,
    allsubscriptiontypes: null,
    newArrivals: null,
    topSellers: null,
    collections: null,
    banners: null,
    allGoldTypes: null,
    selectedRedeem: localStorage.getItem('claimed_redeem')
        ? JSON.parse(localStorage.getItem('claimed_redeem'))
        : null,
    selectedReferCode: localStorage.getItem('claimed_refer_code')
        ? JSON.parse(localStorage.getItem('claimed_refer_code'))
        : null,
};

const mutations = {
    ADD_CATEGORIES: (state, data) => (state.categories = data),
    ADD_NEWARRIVALS: (state, data) => (state.newArrivals = data),
    ADD_TOPSELLERS: (state, data) => (state.topSellers = data),
    ADD_COLLECTIONS: (state, data) => (state.collections = data),
    ADD_BANNERS: (state, data) => (state.banners = data),
    ADD_SUBSCRIPTIONTYPE: (state, data) => (state.allsubscriptiontypes = data),
    ADD_GOLDTYPE: (state, data) => (state.allGoldTypes = data),
    ADD_SELECTED_REDEEM: (state, data) => {
        state.selectedRedeem = data;
        localStorage.setItem(
            'claimed_redeem',
            JSON.stringify(state.selectedRedeem),
        );
    },
    ADD_SELECTED_REFER_CODE: (state, data) => {
        state.selectedReferCode = data;
        localStorage.setItem(
            'claimed_refer_code',
            JSON.stringify(state.selectedReferCode),
        );
    },
};

const getters = {
    getCategories: state => state.categories,
    getNewArrivals: state => state.newArrivals,
    getTopSellers: state => state.topSellers,
    getCollections: state => state.collections,
    getBanners: state => state.banners,
    getAllSubscriptionTypes: state => state.allsubscriptiontypes,
    getAllGoldTypes: state => state.allGoldTypes,
    getSelectedRedeem: state => state.selectedRedeem,
    getSelectedReferCode: state => state.selectedReferCode,
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
};

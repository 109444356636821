const state = {
  buyerguideActive: false,
  blogs : []
};

const mutations = {
  SHOW_BUYERGUIDE(state) {
    state.buyerguideActive = true;
  },
  HIDE_BUYERGUIDE(state) {
    state.buyerguideActive = false;
  },
  ADD_BLOGS (state, data) {
    state.blogs = data.blogs
  },
  APPEND_BLOG(state, blog){
    state.blogs = [...state.blogs, blog]
  }
};

const actions = {
  showBuyerguide({ commit }) {
    commit('SHOW_BUYERGUIDE');
  },
  hideBuyerguide({ commit }) {
    commit('HIDE_BUYERGUIDE');
  }
};

const getters ={
  getBlogs : state => state.blogs
}


export default {
  namespaced : true,
  state,
  mutations,
  getters,
  actions
}
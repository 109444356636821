const state = {
    comments: [],
};

const mutations = {
    ADD_COMMENTS(state, data) {
        state.comments = data.comments;
    },
    APPEND_COMMENT(state, comment) {
        state.comments = [...state.comments, comment];
    },
};

const actions = {};

const getters = {
    getComments: state => state.comments,
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};

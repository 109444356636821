const state = {
    carts: localStorage.getItem('cart')
        ? JSON.parse(localStorage.getItem('cart'))
        : [],
};

const mutations = {
    ADD_CARTS: (state, data) => {
        state.carts.push(data);
        localStorage.setItem('cart', JSON.stringify(state.carts));
    },

    UPDATE_CART: (state, { index: index, payload: data }) => {
        state.carts.splice(index, 1, data);
        localStorage.setItem('cart', JSON.stringify(state.carts));
    },

    REMOVE_CART: (state, index) => {
        state.carts.splice(index, 1);
        localStorage.setItem('cart', JSON.stringify(state.carts));
    },

    RESET_CART: state => {
        state.carts = [];
        localStorage.removeItem('cart');
    },
};

const actions = {
    updateCart: ({ state, commit }, payload) => {
        const index = state.carts.findIndex(
            cart =>
                cart.id == payload.id &&
                cart.product_variant_id == payload.product_variant_id,
        );

        if (index > -1) {
            commit('UPDATE_CART', { index, payload });
        } else {
            commit('ADD_CARTS', payload);
        }
    },
};

const getters = {
    getCarts: state => state.carts,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

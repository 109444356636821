import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { createProvider } from './graphql/vue-apollo';
import Vuelidate from 'vuelidate';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueYouTubeEmbed from 'vue-youtube-embed';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import GAuth from 'vue-google-oauth2';
// import VueMeta from 'vue-meta';
import 'regenerator-runtime/runtime';
import VueHorizontal from 'vue-horizontal';
Vue.use(VueHorizontal);
const gauthOption = {
    clientId:
        '251499252883-8aknr1pqrs30r050re80h8o49mqot676.apps.googleusercontent.com',
    scope: 'email',
    plugin_name: 'PyaeSoneShin',
    prompt: 'select_account'
};
Vue.use(GAuth, gauthOption);
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
Vue.use(VueYouTubeEmbed);
// Vue.use(VueMeta);
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyBReXGRRPTIY5JoFnweNoBkiN3PWmtpZQM',
    },
    installComponents: true,
});

Vue.use(Vuelidate);

import VueSocialSharing from 'vue-social-sharing';

Vue.use(VueSocialSharing);

/* HERO ICONS */
import {
    HeartIcon,
    ShoppingBagIcon,
    UserIcon,
    MenuIcon,
    DownloadIcon,
    KeyIcon,
    MailIcon,
    TagIcon,
    PhoneIcon,
    LocationMarkerIcon,
    LogoutIcon,
    LockOpenIcon,
} from '@vue-hero-icons/outline';
Vue.component('heart-icon', HeartIcon);
Vue.component('key-icon', KeyIcon);
Vue.component('shopping-bag-icon', ShoppingBagIcon);
Vue.component('user-icon', UserIcon);
Vue.component('menu-icon', MenuIcon);
Vue.component('download-icon', DownloadIcon);
Vue.component('mail-icon', MailIcon);
Vue.component('tag-icon', TagIcon);
Vue.component('location-icon', LocationMarkerIcon);
Vue.component('phone-icon', PhoneIcon);
Vue.component('logout-icon', LogoutIcon);
Vue.component('lock-open-icon', LockOpenIcon);

/* FONT-AWESOME ICONS */
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faEye,
    faSearch,
    faEyeSlash,
    faDollarSign,
    faChevronUp,
    faChevronDown,
    faSpinner,
    faChevronRight,
    faChevronLeft,
    faSortAmountDown,
    faExclamationCircle,
    faPaperPlane,
    faPlusCircle,
    faRedo,
    faInfoCircle,
    faTimes,
    faCopy,
    faFileDownload,
    faEdit,
    faTruck,
    faShoppingCart,
    faTrash,
    faShieldAlt,
    faShieldVirus,
    faBars,
    faHeart,
    faCrown,
    faArrowDown,
    faKey,
    faCreditCard,
    faTag,
    faShareAlt,
    faCheckCircle,
    faHourglassEnd,
    faTimesCircle,
    faReceipt,
} from '@fortawesome/free-solid-svg-icons';
import {
    faTrashAlt,
    faUser,
    faEnvelope,
    faGem,
    faArrowAltCircleDown,
} from '@fortawesome/free-regular-svg-icons';
import {
    faTwitter,
    faFacebookF,
    faInstagram,
    faYoutube,
    faViber,
    faApple,
    faAndroid,
    faGoogle,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* GLOBAL JAVASCRIPT */
import 'bootstrap/dist/js/bootstrap.min.js';

/* GLOBAL CSS */
import '@/sass/abstract/variables.scss';
import '@/sass/base/base.scss';
import 'swiper/swiper-bundle.css';

// vue-i18n for localization
import VueI18n from 'vue-i18n';
import { messages } from './locales/index';

const axios = require('axios').default;

Vue.config.productionTip = false;
Vue.prototype.axios = axios;

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'en',
    messages,
});

library.add([
    faSearch,
    faHeart,
    faHourglassEnd,
    faGem,
    faShareAlt,
    faKey,
    faEdit,
    faSpinner,
    faEnvelope,
    faTrash,
    faTwitter,
    faTag,
    faFacebookF,
    faViber,
    faInstagram,
    faYoutube,
    faApple,
    faGoogle,
    faAndroid,
    faYoutube,
    faEye,
    faCreditCard,
    faTruck,
    faEyeSlash,
    faDollarSign,
    faChevronUp,
    faChevronDown,
    faChevronRight,
    faChevronLeft,
    faTimesCircle,
    faSortAmountDown,
    faTrashAlt,
    faExclamationCircle,
    faPaperPlane,
    faPlusCircle,
    faRedo,
    faInfoCircle,
    faTimes,
    faCheckCircle,
    faCopy,
    faFileDownload,
    faShoppingCart,
    faUser,
    faBars,
    faArrowAltCircleDown,
    faShieldAlt,
    faShieldVirus,
    faCrown,
    faArrowDown,
    faReceipt,
]);

Vue.component('font-awesome-icon', FontAwesomeIcon);

new Vue({
    i18n,
    router,
    store,
    apolloProvider: createProvider(),
    render: h => h(App),
}).$mount('#app');

// bootstrap
import 'bootstrap/dist/js/bootstrap.min.js';

import RewardHistoryGql from '@/graphql/queries/point/RewardHistory.gql';

const RewardHistory = {
    methods: {
        getRedeemHistory() {
            this.$apollo
                .query({
                    query: RewardHistoryGql,
                    fetchPolicy: 'no-cache',
                    variables: {
                        type: 'shwepyae',
                    },
                })
                .then(response => {
                    this.$store.commit(
                        'point/ADD_REDEEM_LOYAL_HISTORIES',
                        response.data.rewardHistory,
                    );
                })
                .catch(error => {
                    // console.log(error);
                    // const err = error;
                    return error;
                });

            this.$apollo
                .query({
                    query: RewardHistoryGql,
                    fetchPolicy: 'no-cache',
                    variables: {
                        type: 'ngwepyae',
                    },
                })
                .then(response => {
                    this.$store.commit(
                        'point/ADD_REDEEM_ACTIVITY_HISTORIES',
                        response.data.rewardHistory,
                    );
                })
                .catch(error => {
                    // console.log(error);
                    // const err = error;
                    return error;
                });

            this.$apollo
                .query({
                    query: RewardHistoryGql,
                    fetchPolicy: 'no-cache',
                    variables: {
                        type: 'referral',
                    },
                })
                .then(response => {
                    this.$store.commit(
                        'point/ADD_REDEEM_REFERRAL_HISTORIES',
                        response.data.rewardHistory,
                    );
                })
                .catch(error => {
                    // console.log(error);
                    // const err = error;
                    return error;
                });
        },
    },
};

export default RewardHistory;

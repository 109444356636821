import AddToWishListGQL from '@/graphql/mutations/wishlist/AddToWishlist.gql';
import RemoveFromWishList from '@/graphql/mutations/wishlist/RemoveFromWishlist.gql';
import { mapGetters } from 'vuex';
import Wishlist from '@/graphql/queries/wishlist/Wishlists.gql';

const AddToWishList = {
    computed: {
        ...mapGetters({
            wishlists: 'wishlist/getWishlists',
            isLoggedIn: 'auth/isLoggedIn',
        }),
    },

    methods: {
        getWishlists() {
            this.$apollo
                .query({
                    query: Wishlist,
                    fetchPolicy: 'no-cache',
                })
                .then(reponse => {
                    this.$store.commit('wishlist/ADD_WISHLIST', {
                        wishlists: reponse.data.wishlists.data,
                        count: reponse.data.wishlists.paginatorInfo.count,
                    });
                })
                .catch(error => {
                    console.log(error.reponse);
                });
        },

        addToWish(id) {
            console.log(id);
            this.$apollo
                .mutate({
                    mutation: AddToWishListGQL,

                    variables: {
                        product_id: id,
                    },
                })
                .then(response => {
                    console.log('add to');
                    console.log(response.data);
                    this.getWishlists();
                })
                .catch(error => {
                    console.log(error);
                });
        },

        removeFromWish(id) {
            this.$apollo
                .mutate({
                    mutation: RemoveFromWishList,

                    variables: {
                        product_id: id,
                    },
                })
                .then(response => {
                    console.log(response.data);
                    this.getWishlists();
                })
                .catch(error => {
                    console.log(error);
                });
        },

        hasInWishList(id) {
            if (
                this.isLoggedIn &&
                this.wishlists &&
                this.wishlists.length > 0
            ) {
                const hasInWish = this.wishlists.find(
                    wishlist => wishlist.id == id,
                );
                return hasInWish;
            }
        },

        toggleWish(id) {
            var el = document.getElementById(`active-wish-${id}`);
            if (!this.hasInWishList(id)) {
                el.classList.add('wish-list');
                this.addToWish(id);
            } else {
                el.classList.remove('wish-list');
                this.removeFromWish(id);
            }
        },

        // addWish(id) {
        //     var el = document.getElementById(`active-wish-${id}`);
        //     el.classList.add('active-icon');
        //     this.addToWish(id);
        // },

        // removeWish(id) {
        //     var el = document.getElementById(`inactive-wish-${id}`);
        //     el.classList.remove('active-icon');
        //     this.removeFromWish(id);
        // },
    },
};

export default AddToWishList;
